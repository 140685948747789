<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__wrap">
			<div class="layer__inner">
				<header class="layer__header">
					<h3 class="layer__title">
						{{t('10017')}}
					</h3>
					<button type="button" class="btn-close" @click="handleClose">
						<i class="icon icon-close--light"></i>
					</button>
				</header>
				<div class="layer__body">
					<div class="layer__box">
						<div class="feed-filter">
							<div class="btns">
								<label>
									<input type="radio" class="rad blind" value="recommend" v-model="tmpChecked">
									<span class="btn btn-cancel">
										{{t('10663')}}
									</span>
								</label>
								<label>
									<input type="radio" class="rad blind" value="recent" v-model="tmpChecked">
									<span class="btn btn-cancel">
										{{t('10664')}}
									</span>
								</label>
							</div>
							<!-- [D] 활성화 표시 active 클래스 추가 -->
							<ul class="layer__list">
								<li><button type="button" :class="{active: tmpSelectedItems.indexOf('hot_place') !== -1}" @click="handleSelect('hot_place')">{{t('10665')}}</button></li>
								<li><button type="button" :class="{active: tmpSelectedItems.indexOf('good_travel') !== -1}" @click="handleSelect('good_travel')">{{t('10666')}}</button></li>
								<li><button type="button" :class="{active: tmpSelectedItems.indexOf('good_food') !== -1}" @click="handleSelect('good_food')">{{t('10667')}}</button></li>
							</ul>
							<div class="btns">
								<button type="button" class="btn btn-submit" :class="{'btn-disabled': tmpSelectedItems.length === 0}" @click="handleSubmit(tmpChecked, tmpSelectedItems)" :disabled="tmpSelectedItems.length === 0">{{t('10668')}}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 

import i18n from "@/i18n"; 
export default {
	props: ['handleClose', 'handleSubmit', 'checked', 'selectedItems'],
	setup(props) {
		const checked = ref(props.checked);
		const selectedItems = ref(props.selectedItems);
		const { t }= useI18n() 

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
		
		return {
			tmpChecked: checked,
			tmpSelectedItems: selectedItems,
			t,  //번역필수 모듈
		  i18n, 
			handleSelect: (select) => {
				if (selectedItems.value.indexOf(select) === -1) {
					selectedItems.value.push(select);
				} else {
					selectedItems.value = selectedItems.value.filter(item => item !== select);
				}
			},
		}
	},
}
</script>