<template>
	<TheHeader></TheHeader>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub">
			<section id="feed" class="feed">
				<div class="feed__wrap">
					<div class="feed__slider">
						<!-- [D] 피드 슬라이드 -->
						<swiper v-if="feeds.length > 0" @slideChange="setSliderPlay" :direction="'vertical'" :slidesPerView="'auto'" :spaceBetween="16" :loop="false">
							<swiper-slide v-for="(feed) in feeds" :key="feed.FEED_NO">
								<router-link :to="'/feed/' + feed.FEED_NO" custom v-slot="{ navigate }" >
									<div class="feed__inner" @click="navigate">
											<div class="feed__scroll" >
												<img src="@/assets/images/btn_scroll.png" ref="scroll" alt="스크롤">
											</div>
											<div class="feed__video" v-if="feed.SORT_SEQ === '1'">
												<video class="feed_video_m" onloadeddata :data-feed-url="feed.FEED_URL" :data-poster-url="feed.IMG_URL" :data-feed-id="feed.FEED_NO" autoplay loop="loop" ref="video" :muted="mute" playsinline
												:src="feed.FEED_URL" :poster="feed.IMG_URL"></video>
											</div>
											<div class="feed__video" v-else>
												<video class="feed_video_m" onloadeddata :data-feed-url="feed.FEED_URL" :data-poster-url="feed.IMG_URL" :data-feed-id="feed.FEED_NO" autoplay loop="loop" ref="video" :muted="mute" playsinline
												></video>
											</div>
										<div class="feed__info" v-if="feed.TEXT_DISP === 'Y'">
											<h2 class="feed__title">{{feed.FEED_NM}}</h2>
											<p class="feed__slogan">{{feed.FEED_SUB}}</p>
											<p class="feed__addr">{{feed.FEED_LOCTEXT}}</p>
											<div class="feed__tag">
												<span>#{{feed.HASH_TAG}}</span>
											</div>
										</div>
										<div class="feed__author">
											<div class="author">
												<div class="author__avatar" :style="{'background-image': 'url(\'' + feed.PROFILE_IMG + '\')'}">
													{{feed.PROFILE_NNM}}
												</div>
												<div class="author__name">
													{{feed.PROFILE_NNM}}
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</swiper-slide>
						</swiper>
					</div>
					<ul class="item__menu">
						<li>
							<button type="button" @click="handleOpenFilter">
								<i class="icon icon-filter--point"></i>
							</button>
						</li>
						<!--[D] 음소거 일때 -->
						<li v-if="mute">
							<button type="button" class="btn-mute" @click="toggleMute" id="btn-mute">
								<i class="icon icon-mute"></i>
							</button>
						</li>
						<!--[D] 음소거 아닐때 -->
						<li v-if="!mute">
							<button type="button" class="btn-mute active" @click="toggleMute" id="btn-mute">
								<i class="icon icon-mute"></i>
							</button>
						</li>
					</ul>
				</div>
			</section>
		</div>
	</div>
	<TheNavigation></TheNavigation>
	<Filterfeed v-if="openFilter" :handleClose="handleCloseFilter" :handleSubmit="handleFilterSubmit" :checked="checked" :selectedItems="selectedItems"></Filterfeed>
</template>
<style>
/*.wrap {min-height:100vh;padding-bottom:0}*/
</style>
<script type="text/javascript">

</script>
<script>
import { computed, ref, watch,onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Filterfeed from '@/components/layers/Filter_feed';
export default {
	data() {
  	  	return {
	      mute: true
	    }
      }, 
      methods: {
        toggleMute() {
		  this.mute = !this.mute;
		},
      },
	setup() {
		const store = useStore();
		const openFilter = ref(false);
		const checked = ref("recommend");
		const first_video =ref()
		const selectedItems = ref(["hot_place", "good_travel", "good_food"]);
		const feeds = computed(() => store.state.mfd01.mfd0101);

		watch(() => [selectedItems.value, checked.value], ([selectedItems, checked]) => {
			const filter = selectedItems.reduce((acc, cur) => {
				acc[cur] = "Y";
				return acc;
			}, {});
			store.dispatch("mfd01/fetchMfd0101", {sort: checked === "recommend" ? "01" : "02", ...filter});
		}, {immediate: true})

		store.watch(() => [feeds.value], () => {
			const swiper = document.querySelector('.swiper-container');
			const prevFeedId = sessionStorage.getItem("nowFeed");
			if(prevFeedId && swiper) { 
				sessionStorage.removeItem("nowFeed");
				//console.log(prevFeedId);

				for(let i = 0; i < swiper.swiper.slides.length; i++) {
					const video = swiper.swiper.slides[i].querySelector('video')
					if(video.dataset.feedId === prevFeedId) {
						swiper.swiper.slideTo(i);
						break;
					}
				}
				setSliderPlay(swiper.swiper);
			}
			else if(swiper) {
				swiper.swiper.slideTo(0);
				setSliderPlay(swiper.swiper);
			}
		})
		const setSliderPlay = async (swiper) => {
			const videos = document.querySelectorAll('video');
			await Promise.all(Array.prototype.map.call(videos, (video) => {
				return video.pause();
			}));
			const activeIndex = swiper.activeIndex;
			const activeSlideVideo = swiper.slides[activeIndex].querySelector('video');	
			const activeSlidescroll = swiper.slides[activeIndex].querySelector('.feed__scroll');	
			const nextSlideVideo = swiper.slides[activeIndex + 1] ? swiper.slides[activeIndex + 1].querySelector('video') : null;
			const nextNextSlideVideo = swiper.slides[activeIndex + 2] ? swiper.slides[activeIndex + 2].querySelector('video') : null;
			const prevSlideVideo = swiper.slides[activeIndex - 1] ? swiper.slides[activeIndex - 1 ].querySelector('video') : null;
			const prevprevSlideVideo = swiper.slides[activeIndex - 2] ? swiper.slides[activeIndex - 2].querySelector('video') : null;
			if(activeSlideVideo !== null){
				if(activeSlideVideo.src.length === 0) {
					activeSlideVideo.setAttribute("src", activeSlideVideo.dataset.feedUrl);
				}
				if(activeSlideVideo.poster.length === 0) { 
					activeSlideVideo.setAttribute("poster", activeSlideVideo.dataset.posterUrl);
				}
				activeSlideVideo.play();
				activeSlideVideo.currentTime = 0;
			}
			if(nextSlideVideo !== null) {
				if(nextSlideVideo.src.length === 0) {
					nextSlideVideo.setAttribute("src", nextSlideVideo.dataset.feedUrl);
				}
				if(nextSlideVideo.poster.length === 0) { 
					nextSlideVideo.setAttribute("poster", nextSlideVideo.dataset.posterUrl);
				}
			}
			if(nextNextSlideVideo !== null){
				if(nextNextSlideVideo.src.length === 0) {
					nextNextSlideVideo.setAttribute("src", nextNextSlideVideo.dataset.feedUrl);
				}
				if(nextNextSlideVideo.poster.length === 0) { 
					nextNextSlideVideo.setAttribute("poster", nextNextSlideVideo.dataset.posterUrl);
				}
			}
			if(prevSlideVideo !== null) {
				if(prevSlideVideo.src.length === 0) {
					prevSlideVideo.setAttribute("src", prevSlideVideo.dataset.feedUrl);
				}
				if(prevSlideVideo.poster.length === 0) { 
					prevSlideVideo.setAttribute("poster", prevSlideVideo.dataset.posterUrl);
				}
			}
			if(prevprevSlideVideo !== null){
				if(prevprevSlideVideo.src.length === 0) {
					prevprevSlideVideo.setAttribute("src", prevprevSlideVideo.dataset.feedUrl);
				}
				if(prevprevSlideVideo.poster.length === 0) { 
					prevprevSlideVideo.setAttribute("poster", prevprevSlideVideo.dataset.posterUrl);
				}
			}
		}
		onMounted( ()=>{
			const swiper = document.querySelector('.swiper-container');
			const swiper_active = document.querySelector('.swiper-slide-active');
			const swiper_video = document.querySelector('video');
		})
		return {
			feeds,
			setSliderPlay,
			openFilter,
			handleOpenFilter: () => {
				openFilter.value = true;
			},
			handleCloseFilter: () => {
				openFilter.value = false;
			},
			handleFilterSubmit: (_checked, _selectedItems) => {
				checked.value = _checked;
				selectedItems.value = _selectedItems;
				openFilter.value = false;
			},
			checked,
			selectedItems
		}
	},
	components: {
		Filterfeed
	}
}
</script>